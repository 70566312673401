<template>
  <div class="reports-management pa-8">
    <h5 class="text-center">گزارش‌های مدیریتی</h5>
    <v-row>
      <v-col
        cols="12"
        class="text-right"
        v-for="(report, index) in reportItems"
        :key="index"
      >
        <router-link :to="report.url" class="no-underline">
          <v-card class="common-card" flat>
            <div class="image-box">
              <img :src="report.pictureUrl" />
            </div>
            <div class="text-center mt-10">
              <h6 class="font-weight-bold">{{ report.name }}</h6>
              <!--<p
                class="text-justify blue-grey--text text--darken-2 text-center common-text mb-0 mt-6"
              >
                {{ report.description }}
              </p>-->
            </div>
          </v-card>
        </router-link>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      reportItems: [
        {
          name: "گزارش‌های پزشکان",
          pictureUrl: "/doctor.png",
          url: "/admin/doctorsReports",
        },
        {
          name: "گزارش‌های پرستاران",
          pictureUrl: "/nurse.png",
          url: "/admin/nursesReports",
        },
        {
          name: "گزارش خدمات ارائه شده",
          pictureUrl: "/personnel.png",
          url: "/admin/servicesReports",
        },
        {
          name: "گزارش بیمه ها",
          pictureUrl: "/insurance.png",
          url: "/admin/insurancesReports",
        },
      ],
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss">
.reports-management {
  .image-box {
    width: fit-content;
    margin: 0 auto;

    img {
      width: 300px;
      border-radius: 20px;
    }
  }
}
</style>
